<template>
  <div>
    <div class="d-flex mb-8">
      <div class="titulo-pagina">
        {{ tituloFormulario }}
      </div>
    </div>

    <v-form ref="form" class="row">
      <input-text
        v-model="form.nome"
        class="col-12 col-md-9"
        :label="$t('modulos.unidade_medida.formulario.nome')"
        obrigatorio
        :min="1"
        :max="100"
      />
      <input-text
        v-model="form.simbolo"
        class="col-12 col-md-3"
        :label="$t('modulos.unidade_medida.formulario.simbolo')"
        obrigatorio
        :min="1"
        :max="10"
      />
      <input-grandeza-fisica
        v-model="form.grandezaFisica"
        class="col-12 col-md-9"
        :label="$t('modulos.unidade_medida.formulario.grandezaFisica')"
        :multiplos="false"
        obrigatorio
      />
      <input-select
        v-model="form.sistema"
        class="col-12 col-md-3"
        :options="opcoes.unidadeMedidaSistema"
        :label="$t('modulos.unidade_medida.formulario.sistema')"
        obrigatorio
      />
    </v-form>

    <v-divider class="my-5" />
    <div class="d-flex align-center justify-end">
      <botao-padrao outlined color="secondary" class="mr-2" @click="cancelar">
        <v-icon>$mdiCancel</v-icon>
        {{ $t('geral.botoes.cancelar') }}
      </botao-padrao>
      <botao-padrao @click="confirmacaoSalvar">
        <v-icon>$mdiContentSaveOutline</v-icon>
        {{ $t('geral.botoes.salvar') }}
      </botao-padrao>
    </div>
  </div>
</template>
<script>
import UnidadeMedidaService from '@common/services/cadastros/UnidadeMedidaService.js';
import GrandezaFisicaService from '@common/services/cadastros/GrandezaFisicaService.js';
import { UnidadeMedidaModel } from '@common/models/cadastros/UnidadeMedidaModel.js';
import { DropdownModel } from '@common/models/geral/DropdownModel';
import EnumeradorService from '@common/services/cadastros/EnumeradorService';
import helpers from '@common/utils/helpers';
import { InputGrandezaFisica } from '@components/inputs';

export default {
  components: { InputGrandezaFisica },
  props: ['id'],
  data() {
    return {
      valido: false,
      form: new UnidadeMedidaModel({}),
      opcoes: {
        grandezaFisica: [],
        unidadeMedidaSistema: [],
      },
    };
  },
  computed: {
    tituloFormulario: function () {
      if (this.id) return this.$t('modulos.unidade_medida.titulos.editar');
      return this.$t('modulos.unidade_medida.titulos.novo');
    },
    tooltipBotaoSalvar: function () {
      if (this.valido) return '';
      return this.$t('modulos.unidade_medida.validacoes.formulario_invalido');
    },
  },
  async mounted() {
    await this.buscarGrandezasFisicas();
    await this.buscarUnidadeMedidaSistemaEnum();
    if (this.id) {
      helpers.redirecionarSemPermissao(this, 'UnidadeMedida', 'Editar');
      this.buscar(this.id);
    }
    helpers.redirecionarSemPermissao(this, 'UnidadeMedida', 'Inserir');

    this.$store.dispatch('Layout/alterarTituloPagina', this.tituloFormulario);
  },
  methods: {
    buscar: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      UnidadeMedidaService.buscar(this.id)
        .then((res) => {
          this.form = new UnidadeMedidaModel(res.data);
          this.form.grandezaFisica = new DropdownModel(res.data.grandezaFisica);
          this.valido = true;
          console.log(res.data);
        })
        .catch(() => {
          this.toastErro(this.$t('modulos.unidade_medida.erros.id_invalido'));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    buscarGrandezasFisicas: async function () {
      await GrandezaFisicaService.listar().then((res) => {
        this.opcoes.grandezaFisica = new DropdownModel(res.data.items);
      });
    },
    buscarUnidadeMedidaSistemaEnum: async function () {
      await EnumeradorService.buscar('EnumUnidadeMedidaSistema').then((res) => {
        this.opcoes.unidadeMedidaSistema = res;
      });
    },
    confirmacaoSalvar: function () {
      if (this.id) {
        this.confirmarSalvar().then(() => {
          this.salvar();
        });
      } else this.salvar();
    },
    salvar: function () {
      const valido = this.$refs.form.validate();
      if (!valido) return;
      this.$store.dispatch('Layout/iniciarCarregamento');
      UnidadeMedidaService.salvar(this.form.request)
        .then(() => {
          this.toastSucesso(this.$t(`modulos.unidade_medida.cadastro_sucesso`));
          this.$router.push({ name: 'unidade-medida' });
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    cancelar: function () {
      this.confirmarCancelar().then(() => {
        this.$router.push({ name: 'unidade-medida' });
      });
    },
  },
};
</script>
