import { DropdownModel } from '@common/models/geral/DropdownModel.js';
import _ from 'lodash';
export class UnidadeMedidaModel {
  constructor({ id, nome, simbolo, grandezaFisica, sistema = "Decimal" }) {
    this.id = id;
    this.nome = nome;
    this.simbolo = simbolo;
    this.sistema = sistema;
    this.grandezaFisicaId = grandezaFisica;
    if (grandezaFisica)
      this.grandezaFisica = new DropdownModel(grandezaFisica);
  }

  get request() {
    const retorno = _.cloneDeep(this);
    retorno.grandezaFisicaId = retorno.grandezaFisica?.value;
    return retorno;
  }
}
